import * as React from 'react'
import { TimeStampResponseDTO } from '../models/timestamp'
import { IError } from '../models/error'



interface IActionSelect {
    data: TimeStampResponseDTO
    onCheckIn: () => void
    onCheckOut: () => void
    error?: IError | null
}

export function ActionSelect(p: IActionSelect) {


    return (
        <div className='response-wrapper'>
            <h2>{p.data.ProfileName}</h2>
            <div className='action-pane'>
                <div className='btn secondary' onClick={() => p.onCheckOut()}>Check-out</div>
                <div className='btn primary' onClick={() => p.onCheckIn()}>Check-in</div>
            </div>
            {p.error && p.error != null &&
                <div style={{ marginTop: 10 }}>
                    <h1 style={{color: p.error.color}}>
                        {p.error.title} {p.error.msg !== '' && <>: {p.error.msg}</>}
                    </h1>
                </div>
            }
            {p.data.TimeStampNotValid && p.data.TimeStampNotValid.length > 0 &&
                <div className='warning'>
                    Es gibt {p.data.TimeStampNotValid.length} fehlerhafte Stempelungen
                </div>}
        </div>
    )
}