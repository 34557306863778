
interface Iconstants {
    weekdays: {[index:number]: string}
    localStorageID: string
}

export const constants: Iconstants = {
    weekdays: {
        0: 'So',
        1: 'Mo',
        2: 'Di',
        3: 'Mi',
        4: 'Do',
        5: 'Fr',
        6: 'Sa'
    },
    localStorageID: 'timestamp_apikey',
}