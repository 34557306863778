import * as React from 'react'
import { constants } from '../constants';

export function AdminApiKey() {

    const [txt, setTxt] = React.useState<string>('');
    const save = () => {
        if (txt !== '') {
            localStorage.setItem(constants.localStorageID, txt.trim());
            window.location.reload();
        }
    }

    return (
        <div className='App'>
            <div className='App__ApiInput'>
                <h1>Set API KEY</h1>
                <div style={{ padding:40, marginBottom: 25}}>
                    <input className='form-control' type='text' value={txt} onChange={(e) => setTxt(e.currentTarget.value)} />
                </div>

                <button className='btn' onClick={() => save()}>Save</button>
            </div>
        </div>
    )
}