import * as React from 'react'
import * as Icon from  './../../img/delete-left-solid.svg'
import { IconDeleteLeft } from '../../img/delete-left'

interface IKeyboard {
    txt: string
    addLetter: (l: string) => void
    resetInput: () => void
}

export function Keyboard(p: IKeyboard) {

    const buttons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '-', '.', '/']

    return (
        <div>
            <div className='input-wrapper'>
                <div>
                    <div className='input'>{p.txt}</div>
                    {p.txt !== '' &&
                        <i className='icon' onClick={() => p.resetInput()} >
                                <IconDeleteLeft />
                            </i>
                    }
                </div>
            </div>
            <div className='keyboard-wrapper'>
                <div className='keyboard'>
                    {buttons.map((x, i) => {
                        return <div key={i} onClick={() => p.addLetter(x)}>{x}</div>
                    })}
                </div>
            </div>
        </div >
    )
}