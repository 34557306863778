import React from 'react';
import { constants } from './../constants'
import { Keyboard } from './timestamp/keyboard';
import { TimeStampRequestDTO, TimeStampResponseDTO } from '../models/timestamp';
import { Timestamp_Requests } from '../apirequest/request';
import { ActionSelect } from './actionselect';
import { DateToIsoString } from '../mappings/datetimemapping';
import { IError } from '../models/error';


interface IProps {
  apikey: string
  onResetApiKey: () => void
}
enum appStateEnum {
  none,
  authorization,
  confirmation
}
enum apiStateEnum {
  none,
  working
}
export function TimestampApp(p: IProps) {

  const [date, setDate] = React.useState<Date>(new Date());
  const [txt, setTxt] = React.useState<string>('');
  const [apiState, setApiState] = React.useState<apiStateEnum>(apiStateEnum.none);
  const [appState, setAppState] = React.useState<appStateEnum>(appStateEnum.none);
  const [authorizeResponse, setAuthorizeResponse] = React.useState<TimeStampResponseDTO | null>(null);
  const [error, setError] = React.useState<IError | null>(null)

  React.useEffect(() => {
    setTimeout(() => setDate(new Date()), 5000)
  }, [date])

  const addLetter = (letter: string) => {
    setTxt(txt + letter)
  }

  // function
  const apiAuthorize = () => {
    // don't start two api requests
    if (apiState === apiStateEnum.working) return;

    // if there is the secret combination --..//
    // reset the api key
    if(txt == '--..//') {
      p.onResetApiKey();
      return;
    }

    // init api requests
    let apiRequests = new Timestamp_Requests(p.apikey);

    // api state
    setApiState(apiStateEnum.working);

    apiRequests.authorize({
      CardId: txt
    } as TimeStampRequestDTO)
      .then(r => {
        if (r?.IsError === true) {
          throw new Error(r.ErrorMsg)
        }

        setAuthorizeResponse(r)
        setAppState(appStateEnum.authorization);
      })
      .catch((e) => {
        console.log(e)
        setError({
          color: 'red',
          msg: e.message,
          title: "FEHLER: "
        })
        setTimeout(() => {
          apiCancel()
        }, 2000)
      })
      .finally(() => setApiState(apiStateEnum.none))

  }

  /**
   * cancel all api calls and reset app state
   */
  const apiCancel = () => {
    setApiState(apiStateEnum.none);
    setAppState(appStateEnum.none);
    setAuthorizeResponse(null);
    setError(null);
    setTxt('');
  }

  /**
   * sent the timeclock action checkIn / checkOut to api
   * wait for the response 
   * @param action 0 = checkIn | 1 = checkOut
   */
  const apiActionSelect = (action: number) => {
    // exit if no response is present
    if (authorizeResponse == null) {
      apiCancel();
      return;
    }

    // don't start two api calls
    if (apiState === apiStateEnum.working) return;

    // do the request
    let apiRequests = new Timestamp_Requests(p.apikey);
    apiRequests.confirm({
      Action: action,
      CardId: authorizeResponse.CardId,
      ProfileId: authorizeResponse.ProfileId ?? 0,
      TokenTimeStamp: authorizeResponse.TokenTimeStamp,
      TimeStamp: DateToIsoString(date)
    })
      .then(r => {
        if (r?.Success) {
          setError({
            color: 'green',
            title: 'OK',
            msg: ''
          })
        }
        else {
          setError({
            color: 'red',
            title: "FEHLER: ",
            msg: r?.ErrorMsg ?? ''
          })
        }
      })
      .finally(() => {
        setApiState(apiStateEnum.none);
        setTimeout(apiCancel, 2000);
      })
  }

  // actionfunctions
  const onCheckOut = () => {
    console.log('action check out')
    apiActionSelect(1);
  }
  const onCheckIn = () => {
    console.log('action check in')
    apiActionSelect(0);
  }

  return (
    <div className="App">
      <div className='flex-1 date-wrapper'>
        <span>{constants.weekdays[date.getDay()]}, {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}</span>
      </div>
      <div className='flex-2 time-wrapper'>
        <span className='flex-1'>{(date.getHours() + 100).toString().slice(-2)}</span>
        <span>:</span>
        <span className='flex-1'>{(date.getMinutes() + 100).toString().slice(-2)}</span>
      </div>
      <div className='flex-500'>
        {authorizeResponse == null
          ?
          <Keyboard
            txt={txt}
            addLetter={addLetter}
            resetInput={() => setTxt('')} />
          :
          <ActionSelect
            data={authorizeResponse}
            onCheckIn={onCheckIn}
            onCheckOut={onCheckOut}
            error={error}
          />

        }
      </div>
      <div className='flex-2 action-wrapper'>
        {error !== null && appState === appStateEnum.none && <div style={{ color: error.color }}><b>{error.title}</b>{error.msg}</div>}
        {appState === appStateEnum.none ?
          <div className='btn primary' onClick={() => apiAuthorize()}>
            weiter
          </div>
          :
          <div className='btn' onClick={() => apiCancel()}>
            Abbrechen
          </div>
        }
      </div>
    </div>
  );
}
