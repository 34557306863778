import React from 'react';
import './App.css';
import { constants } from './constants'
import { AdminApiKey } from './components/apikey_input';
import { TimestampApp } from './components/timestamp';

function App() {


  const [loading, setLoading] = React.useState<boolean>(true);
  const [apikey, setApikey] = React.useState<string | null>(null);

  React.useEffect(() => {
    var item = localStorage.getItem(constants.localStorageID);
    setApikey(item);
    setLoading(false);
  }, [])

  const ResetApiKey = () => {
    localStorage.clear()
    setApikey(null);
  }


  if(loading) {
    return (
      <div className='App'>
        <div>loading ...</div>
      </div>
    )
  }

  if(apikey== null) {
    return <AdminApiKey />
  }

  return <TimestampApp 
            apikey={apikey}
            onResetApiKey={() => ResetApiKey()} />

}

export default App;
