import { urls } from "./urls";
import { TimeStampRequestDTO, TimeStampResponseDTO, TimeStampConfirmRequestDTO, TimeStampConfirmResponseDTO } from "../models/timestamp";


type HttpMethode = 'GET' | 'POST' | 'PUT' | 'DELETE';


export class Timestamp_Requests {
    /**
     * all requests for the timestamp data
     */
    constructor(apiKey: string) {
        if (apiKey == null || apiKey === '') throw new Error("Api key cannot be empty")
        this.apiKey = apiKey;
    }

    /**
     * variable for storing the api key
     * which is included in all api requests
     */
    private apiKey: string;



    // funcitons

    private async makeRequest<T>(type: HttpMethode, url: string, body: any): Promise<T> {

        var fetchObj: RequestInit = {
            method: type.toString(),
            headers: {
                "Content-Type": "application/json",
                "x-api-key": this.apiKey
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            cache: 'no-cache',
            mode: 'cors',
        } as RequestInit;


        if (body && body != null) {
            fetchObj.body = JSON.stringify(body);
        }

        try {
            console.log('start api request')
            var request = await fetch(url, fetchObj)
            if (request.ok) {
                var json = request.json() as T;
                console.log(json)
                return json;
            }
        }
        catch (e) {
            console.log(e);
            throw e;
        }


        throw new Error('error in request');

    }


    /**
     * do the authorize request. the api returns the personal data which is found with the cardId / personal token id
     * and return a tokenTimestamp to validate further requests
     * @param personalData cardId
     * @returns 
     */
    async authorize(personalData: TimeStampRequestDTO): Promise<TimeStampResponseDTO | null> {

        try {
            var result = this.makeRequest<TimeStampResponseDTO>('POST', urls.authorize, personalData);
            return result;
        }
        catch (error) {
            console.log(error)
            return null;
        }
    }


    /**
     * post the check-in / check-out action with the correct profileId and the tokentimestamp
     * @param actionData chcek-in 0 // check-out 1 and the tokentimestamp to validate the request
     * @returns
     */
    async confirm(actionData: TimeStampConfirmRequestDTO): Promise<TimeStampConfirmResponseDTO | null> {

        try {
            var result = this.makeRequest<TimeStampConfirmResponseDTO>('POST', urls.confirm, actionData)
            return result;
        }
        catch (error) {
            console.log(error);
            return null;
        }
    }
}

